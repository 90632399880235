import React, { useState } from 'react';
import { HomeOutlined, LoginOutlined, LogoutOutlined, LinkOutlined, ProjectOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu, Row, Col} from 'antd';
import { useNavigate } from 'react-router';


const HorizontalMenu: React.FC<{isSubmitted: boolean}> = ({isSubmitted}) => {

  type MenuItem = Required<MenuProps>['items'][number];

  const [current, setCurrent] = useState('1');
  const navigate = useNavigate();

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  const external_key: Array<string> = [
    '2','3','4'
  ];

  const leftItems: MenuProps['items'] = [
    getItem('Home', '1', <HomeOutlined />),
    //getItem('Scream Project', '2', <ProjectOutlined />),
    getItem ('SCREAM - App', '2', <LinkOutlined />, [
      {
        label: (
          <a href="https://scream.alidalab.it/login" target="_blank" rel="noopener noreferrer">
            Machine Learning
          </a>
        ),
        key: '2:1',
      },
      {
        label: (
          <a href="https://scream-superset.alidalab.it/login" target="_blank" rel="noopener noreferrer">
            Analytics
          </a>
        ),
        key: '2:2',
      }
      ]
    ),
    getItem ('Partners', '3', <LinkOutlined />, [
        {
          label: (
            <a href="https://www.eng.it" target="_blank" rel="noopener noreferrer">
              ENG
            </a>
          ),
          key: '3:1',
        },
        {
          label: (
            <a href="https://www.iprel.it" target="_blank" rel="noopener noreferrer">
              Iprel
            </a>
          ),
          key: '3:2',
        },
        {
          label: (
            <a href="https://www.eka.it" target="_blank" rel="noopener noreferrer">
              Eka
            </a>
          ),
          key: '3:3',
        }
      ]
    ),
    {
      label: (
        <a href="https://www.sofidel.com/" target="_blank" rel="noopener noreferrer">
          Case Study - Sofidel
        </a>
      ),
      key: '4:2',
      icon: <LinkOutlined />
    },
  ];

  const logInItem: MenuProps['items'] = [
    getItem('Login', '6', <LoginOutlined />)
  ];

  const logOutItem: MenuProps['items'] = [
    getItem('Logout', '6', <LogoutOutlined />)
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    let ext_check: boolean = false;
    for (let key of external_key) {
      ext_check = e.key.startsWith(key);
      
      if (ext_check)
        break;
    }

    if ((ext_check) === false) {
      setCurrent(e.key);
      navigate(e.key);
    }

  };

  return (
    <div style={{paddingTop: 40}}>
      <Row>
        <Col span={20}>
          <Menu onClick={onClick} 
                selectedKeys={[current]} 
                mode="horizontal" 
                items={leftItems} 
                defaultSelectedKeys={['1']}
                style={{fontSize: 16, backgroundColor: '#f0f0f0' }}
          />
        </Col>
        {/*<Col span={4} offset={8}>
          <Menu onClick={onClick} 
                //selectedKeys={[current]} 
                mode="horizontal"
                items={isSubmitted == false ? logInItem : logOutItem} 
                style={{fontSize: 16 }}
          />
        </Col>*/}
      </Row>
    </div>
  );
};

export default HorizontalMenu;