import { Divider, Space, Typography, theme } from 'antd';
//import progetto from '../asset/project_cont/progetto.png';
import progetto from '../asset/project_cont/progetto.png';
import obiettivo from '../asset/project_cont/obiettivo.png';
import soluzioni from '../asset/project_cont/soluzioni.png';

const { Paragraph, Text } = Typography;

const containerImg: React.CSSProperties = {
  position: 'relative',
  textAlign: 'center',
  color: 'white'
};

const topLeft: React.CSSProperties = {
  position: 'absolute',
  left: '9%',
  top: '17%'
};

const Prova: React.FC = () => {

  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 24,
    background: token.colorBgBase,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  return (
    <Space align="center" direction="vertical">
      <div style={containerImg}>
        <img src={progetto} alt="" style={{width: '85%'}} />
        <div style={topLeft}>
          <Paragraph style={{textAlign: 'center', minHeight: '70px', fontSize: '3.5vw', color: 'white' }}>
            Il Progetto
          </Paragraph>
        </div>
      </div>
      <div style={{maxWidth: '1080px', textAlign: 'center'}}>
      <Typography>
        <Paragraph style={{textAlign: 'justify', minHeight: '70px', fontSize: '1.3vw', lineHeight: '35px'}}>
          Il<Text style={{ fontSize: '1.3vw' }} strong> Progetto </Text><Text style={{ fontSize: '1.3vw' }} strong>SCREAM</Text> (
          <Text style={{ fontSize: '1.3vw'}} strong>S</Text>e<Text style={{ fontSize: '1.3vw'}} strong>C</Text>
          ure <Text style={{ fontSize: '1.3vw'}} strong>R</Text>emote <Text style={{ fontSize: '1.3vw'}} strong>E</Text>
          quipment and <Text style={{ fontSize: '1.3vw'}} strong>A</Text>sset <Text style={{ fontSize: '1.3vw'}} strong>M</Text>
          onitoring) si occupa del monitoraggio, manutenzione e gestione remota delle macchine di produzione, in 
          piena sicurezza e in tempo reale. La piattaforma distribuita SCREAM integra e offre 
          servizi per la gestione dei Big Data e mira a fornire una serie di analitiche distribuite 
          e personalizzate per il supporto di processi decisionali riguardanti le macchine e le 
          infrastrutture di produzione.
        </Paragraph>
      </Typography>
      </div>
      
      <div style={containerImg}>
        <img src={obiettivo} alt="" style={{width: '85%'}} />
        <div style={topLeft}>
          <Paragraph style={{textAlign: 'center', minHeight: '70px', fontSize: '3.5vw', color: 'white' }}>
            L'Obiettivo
          </Paragraph>
        </div>
      </div>
      <div style={{maxWidth: '1080px', textAlign: 'center'}}>
      <Typography>
        <Paragraph style={{textAlign: 'justify', minHeight: '70px', fontSize: '1.3vw', lineHeight: '35px'}}>
          L'<Text style={{ fontSize: '1.3vw' }} strong>Obiettivo </Text>
          principale del progetto <Text style={{ fontSize: '1.3vw' }}>SCREAM</Text> è quello di 
          supportare le aziende manifatturiere a implementare processi di smart manufacturing attraverso la 
          realizzazione di una piattaforma software avanzata di manutenzione e monitoraggio predittivo che 
          permette di controllare, manutenere e gestire attrezzature di produzione e macchine utensili da 
          remoto. SCREAM supporta lo sviluppo e la messa in campo di soluzioni <Text style={{ fontSize: '1.3vw' }} strong>Industry 4.0 </Text> 
          complete che, sfruttando i nuovi paradigmi Big Data, Artificial Intelligence e Analytics, permettono di 
          massimizzare la produzione e allo stesso tempo di ridurre i costi operativi e di manutenzione.
        </Paragraph>
      </Typography>
      </div>

      <div style={containerImg}>
        <img src={soluzioni} alt="" style={{width: '85%'}} />
        <div style={topLeft}>
        <Paragraph style={{textAlign: 'center', minHeight: '70px', fontSize: '3.5vw', color: 'white' }}>
            Le Soluzioni
          </Paragraph>
        </div>
      </div>
      <div style={{maxWidth: '1080px', textAlign: 'center'}}>
      <Typography>
        <Paragraph style={{textAlign: 'justify', minHeight: '70px', fontSize: '1.3vw', lineHeight: '35px'}}>
          Le <Text style={{ fontSize: '1.3vw' }} strong> Soluzioni </Text>
          messe a disposizione su <Text style={{fontSize: '1.3vw'}}>SCREAM</Text> sono facilmente 
          fruibili grazie a tecnologie di virtualizzazione, web, app mobile, garantendo la sicurezza dei dati 
          lungo tutta la loro catena (raccolta, trasmissione, elaborazione e utilizzo); in questo modo si 
          gestisce in maniera ottimale la performance dei macchinari, permettendo all'operatore di prendere 
          decisioni in tempo reale.
        </Paragraph>
        <Paragraph style={{textAlign: 'left', minHeight: '70px', fontSize: 18, lineHeight: '35px' }}>
        <Text style={{textAlign: 'left', fontSize: '1.3vw' }}>L'implementazione</Text> riguarda:
        <ul>
          <li>Un Gateway per l'accesso sicuro ai macchinari di produzione.</li>
          <li>Servizi Applicativi per sistemi di Monitoraggio e Controllo (M&C) di macchinari di produzione.</li>
          <li>
          <Space size={'small'}>
            Strumenti di Manutenzione Predittiva per i sistemi di M&C e per l'Early Warning. 
            <a href="https://scream.alidalab.it/login" target="_blank" rel="noreferrer">
              SCREAM - Machine Learning
            </a>
            ,
            <a href="https://scream-superset.alidalab.it/login" target="_blank" rel="noreferrer">
              SCREAM - Analytics
            </a>
            </Space>
          </li>
          <li>True Connector per la facilitazione della condivisione di dati.</li>
          <li>Gestione delle Analitiche.</li>
          <li>Gestione dei processi.</li>
        </ul>
      </Paragraph>
      </Typography>
      </div>
    </Space>
    
    
  );
};

export default Prova;