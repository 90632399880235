

const Logout = (props: any) => {

  props.updSubmitted(false);
  
  return (
    <div>
      logout
    </div>
  );

}

export default Logout;