import './custom.css';
import { Layout, Space, Typography } from 'antd';
import HorizontalMenu from './components/Menu';
import { Route, Routes } from 'react-router-dom';
import SlideContent from './pages/SlideContent';
import ProjectContent from './pages/ProjectContent';
import Login from './pages/Login';
import { useState } from 'react';
import Logout from './components/Logout';
import FooterElem from './components/Footer';


const { Header, Content } = Layout;
const { Link } = Typography;

const contentStyle: React.CSSProperties = {
  height: '100%',
  color: '#000',
  lineHeight: '100%',
  textAlign: 'center',
  background: '#f0f0f0',
};

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#000',
  height: '100%',
  paddingInline: 40,
  //lineHeight: '130px',
  backgroundColor: '#f0f0f0',
};

function App() {

  const [isSubmitted, setIsSubmitted] = useState(false);

  const updSubmitted = (isSubmitted: boolean): void => {
    setIsSubmitted(isSubmitted);
  }

  return (
    <div>
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
    <Layout>
      <Header style={headerStyle}>
        <Link href="/" >
          <div className="logo" />
        </Link>
        <HorizontalMenu isSubmitted={isSubmitted} />
        {isSubmitted === true ? <div>------SUBMENU------</div> : ''}
      </Header>
      <Content style={contentStyle}>
        <Routes>
          <Route path='/' element={ <ProjectContent /> } />
          <Route path='1' element={ <ProjectContent /> } />
          {/*<Route path='2' element={ <ProjectContent /> } />*/}
          <Route path='6' index element={ isSubmitted === false ?
            <Login updSubmitted={updSubmitted} /> :
            <Logout updSubmitted={updSubmitted} />}
          />
        </Routes>
      </Content>
      <FooterElem />
    </Layout>
    </Space>
    </div>
  );
}

export default App;
