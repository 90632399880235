import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';

const Login = (props: any) => {

  const [messageApi, contextHolder] = message.useMessage();


  const database = [
    {
      username: "user1",
      password: "pass1"
    },
    {
      username: "user2",
      password: "pass2"
    }
  ];
  

  const errorDesc: string = "Login failed - User e/o Password errati";

  const errorLogin = (errorMsg: string) => {
    messageApi.open({
      type: 'error',
      content: errorMsg,
    });
  };
  
  const onFinish = (values: any) => {
    const userData = database.find((user) => user.username === values.uname);

    if (userData) {
      if (userData.password !== values.pass) {
        // Invalid password
        errorLogin(errorDesc);
        console.log(errorDesc);
      } else {
        //setIsSubmitted(true);
        props.updSubmitted(true);
      }
    } else {
      // Username not found
      errorLogin(errorDesc);
      console.log(errorDesc);
    }

  };
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  
  return (
    <div style={{display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center',
                 paddingTop: '64px',
                 paddingBottom: '54px'
               }}
    >
    {contextHolder}
    <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="uname"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="pass"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
    </Form>
    </div>
  );

}

export default Login;