import { Col, Layout, Row } from 'antd';

import eng from '../asset/partners/eng_res.png';
import eka from '../asset/partners/eka_res.png';
import iprel from '../asset/partners/iprel_res.png';
import sofidel from '../asset/partners/sofidel_res.png';

const { Footer } = Layout;

const FooterElem = () => {

  return (
    <div style={{backgroundColor: '#d9d9d9' , paddingTop: 15, paddingBottom: 12 }}>
      <Row>
        <Col span={8} style={{ textAlign: 'center' }}>
          <a href='https://www.eng.it' target="_blank" rel="noopener noreferrer">
            <img style={{maxWidth: '59%', height: 'auto'}} src={eng} alt=''/>
          </a>
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          <a href='https://www.iprel.it' target="_blank" rel="noopener noreferrer">
            <img style={{maxWidth: '35%', height: 'auto'}} src={iprel} alt=''/>
          </a>
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          <a href='https://www.eka.it' target="_blank" rel="noopener noreferrer">
            <img style={{maxWidth: '35%', height: 'auto'}} src={eka} alt=''/>
          </a>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6} style={{ fontSize: 16, textAlign: 'center' }}>
          Scream ©2023 Created by Eng
        </Col>
      </Row>
      {/*<Footer style={{ fontSize: 16, textAlign: 'center', backgroundColor: '#d9d9d9' }}>
        Scream ©2023 Created by Eng
      </Footer>*/}
    </div>
  );

}

export default FooterElem;